<template>
    <card icon="pe-7s-bandaid"
          :headline="$t('breakdowns.breakdownList.infoboxHeadline')"
          :description="$t('breakdowns.breakdownList.infoboxDescription')">
        <div class="breakdowns text-center">
            <div class="row">
                <template v-if="hasLoaded">
                    <div class="col-md-4">
                        <template v-if="isOnline">
                            <i class="pe-7s-check check" :style="positiveColor"></i>
                            <p class="text-muted">{{$t('visitors.breakdowns.online')}}</p>
                        </template>

                        <template v-else>
                            <i class="pe-7s-close-circle cross" :style="negativeColor"></i>
                            <p class="text-muted">{{$t('visitors.breakdowns.offline')}}</p>
                        </template>
                    </div>

                    <div class="col-md-4">
                        <p class="large">{{$tc('visitors.breakdowns.breakdowns', breakdowns.length, {breakdowns: breakdowns.length} )}}</p>
                        <p class="text-muted">{{$t('visitors.breakdowns.breakdownsLastThirtyDays')}}</p>
                    </div>

                    <div class="col-md-4 last">
                        <p class="large">{{duration}}</p>
                        <p class="text-muted">{{$t('visitors.breakdowns.minutesTotal')}}</p>
                    </div>
                </template>

                <template v-else>
                    <div class="col-md-4">
                        <template>
                            <p class="large mock">Check</p>
                            <p class="text-muted mock">Din hjemmeside er online</p>
                        </template>
                    </div>

                    <div class="col-md-4">
                        <p class="large mock">nedbrud</p>
                        <p class="text-muted">{{$t('visitors.breakdowns.breakdownsLastThirtyDays')}}</p>
                    </div>

                    <div class="col-md-4 last">
                        <p class="large mock">minutter</p>
                        <p class="text-muted">{{$t('visitors.breakdowns.minutesTotal')}}</p>
                    </div>
                </template>
            </div>
        </div>
    </card>
</template>

<style lang="scss" scoped>
    .breakdowns {
        .col-md-4 {
            padding: 15px 0;
            border-right: 1px solid #dedede;

            .check, .cross {
                font-size: 36px;
                margin-bottom: 10px;
            }

            .large {
                font-size: 24px;
            }

            &.last {
                border-right: none;
            }
        }
    }
</style>

<script>
    import Card from '@/app/shared/components/Card'

    const moment = require('moment')

    const BreakdownService = require('@/services/breakdown/BreakdownService')

    export default {
        name: 'BreakdownList',

        data() {
            return {
                breakdowns: [],
                hasLoaded: false,
            }
        },

        computed: {
            duration() {
                let duration = 0

                this.breakdowns.forEach((breakdown) => {
                    if (!breakdown.start) {
                        return
                    }

                    let start = moment(breakdown.start.date)
                    let end = null

                    if (breakdown.end && breakdown.end.date) {
                        end = moment(breakdown.end.date)
                    } else {
                        end = moment()
                    }

                    const diff = end.diff(start)

                    duration += moment.duration(diff).asMinutes()
                })

                if (duration === 0) {
                    return $tc('visitors.breakdowns.minutes', 0, {minutes: 0})
                }

                return moment.duration(duration, "minutes").humanize()
            },

            isOnline() {
                if (this.breakdowns.length === 0) {
                    return true
                }

                const breakdown = this.breakdowns[0]

                return (breakdown.end && breakdown.end.date)
            },

            positiveColor() {
                return {color: $org('colors.standard.statuses.positive.focus')}
            },

            negativeColor() {
                return {color: $org('colors.standard.statuses.negative.focus')}
            }
        },

        mounted() {
            this.load()
        },

        methods: {

            load() {
                const options = {
                    filter_groups: [
                        {
                            filters: [
                                {
                                    key: 'start',
                                    value: moment().subtract(30, 'days').format('YYYY-MM-DD'),
                                    operator: 'gt',
                                },
                                {
                                    key: 'start',
                                    value: moment().add(1, 'day').format('YYYY-MM-DD'),
                                    operator: 'lt',
                                }
                            ]
                        }
                    ],
                    sort: [{
                        key: 'end',
                        direction: 'ASC'
                    }],
                }

                BreakdownService.findAll(options, (response) => {
                    this.hasLoaded = true

                    this.breakdowns = response.data.rows;
                }, () => {
                    this.hasLoaded = false
                })
            }
        },

        components: {
            Card
        }
    }
</script>
