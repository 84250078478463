const BaseService = require('@/services/BaseService')

const namespace = 'breakdowns'

const find = (id, options, cb, errorCallback) => {
    BaseService.get(namespace + '/' + id, options, cb, errorCallback);
}

const findAll = (options, cb, errorCb) => {
    BaseService.get(namespace, options, cb, errorCb)
}

export {
    find,
    findAll
}
